import React from 'react'
import { Table } from 'baseui/table-semantic'

const VTable = ({ overrides, ...props }) => {
  return (
    <Table
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.light,
            ...overrides?.Root?.style
          })
        },
        TableHeadCell: {
          style: ({ $theme }) => ({
            fontSize: '12px',
            fontWeight: 500,
            paddingTop: $theme.sizing.scale300,
            paddingBottom: $theme.sizing.scale300,
            paddingLeft: $theme.sizing.scale500,
            paddingRight: $theme.sizing.scale500,
            backgroundColor: $theme.colors.transparent,
            borderBottomColor: $theme.colors.transparent,
            color: 'rgb(4, 17, 43, .6)'
          })
        },
        TableBodyCell: {
          style: ({ $theme }) => ({
            fontSize: $theme.typography.LabelXSmall.fontSize,
            paddingTop: $theme.sizing.scale400,
            paddingBottom: $theme.sizing.scale400,
            paddingLeft: $theme.sizing.scale600,
            paddingRight: $theme.sizing.scale600,
            borderBottomColor: $theme.colors.gray,
            verticalAlign: 'middle',
            wordBreak: 'break-all',
            ...overrides?.TableBodyCell?.style
          })
        },
        TableBody: {
          style: ({ $theme }) => ({
            ...$theme.borders.border400,
            backgroundColor: $theme.colors.white
          })
        },
        ...overrides
      }}
      {...props}
    />
  )
}
export default VTable
