import React, { useState } from 'react'
import { Input, SIZE } from 'baseui/input'
import { useStyletron } from 'baseui'
import _uniqueId from 'lodash/uniqueId'

const VInput = React.forwardRef(
  (
    {
      size = '',
      overrides,
      value,
      label, // Label text for the floating label
      startEnhancer,
      endEnhancer,
      tags, // Tags to be displayed in the input
      ...props
    },
    ref
  ) => {
    const [css, theme] = useStyletron()

    const [focused, setFocused] = useState(false)
    const onFocus = () => setFocused(true)
    const onBlur = () => {
      if (props.onBlur) {
        props.onBlur()
      }
      setFocused(false)
    }

    const [id] = useState(_uniqueId('input-'))

    const labelStyles = css({
      position: 'absolute',
      paddingLeft: '16px',
      // paddingTop:
      //   focused || value || (value && value.length > 0) || (tags && tags.length > 0)
      //     ? '8px'
      //     : '18px',
      paddingTop: '8px',
      color: '#04112B',
      // fontSize:
      //   focused || value || (value && value.length > 0) || (tags && tags.length > 0)
      //     ? '11px'
      //     : '14px',
      fontSize: '11px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: '12px',
      opacity: 0.6,
      zIndex: 1, // Make sure label is above input
      transition: '.3s all ease-in-out'
    })

    // Conditional styles for Input and InputContainer if there is a label
    const inputConditionalStyles = label
      ? {
          paddingTop: '12px', // Make space for the floating label
          paddingBottom: '8px', // Maintain vertical rhythm
          paddingLeft: '14px', // Adjust as needed
          width: '100%',
          flex: '1'
        }
      : {}

    const inputContainerConditionalStyles = label
      ? {
          paddingTop: tags ? '16px' : '8px', // Maintain vertical rhythm
          paddingLeft: tags ? '2px' : '0px',
          width: '100%',
          flex: 1
        }
      : {}

    const inputRootConditionalStyles = label
      ? {
          flex: '1'
        }
      : {}

    return (
      <div className={css({ display: 'flex', position: 'relative', width: '100%' })}>
        {label && (
          <label className={labelStyles} htmlFor={id}>
            {label}
          </label>
        )}
        <Input
          id={id}
          size={size || SIZE.compact}
          value={value}
          endEnhancer={endEnhancer || ''}
          startEnhancer={startEnhancer || ''}
          ref={ref} // Pass the ref here
          {...props}
          overrides={{
            InputContainer: {
              style: ({ $theme }) => ({
                position: 'relative',
                backgroundColor: $theme.colors.transparent,
                ...inputContainerConditionalStyles,
                ...overrides?.InputContainer?.style
              })
            },
            Input: {
              style: {
                ...inputConditionalStyles,
                ...overrides?.Input?.style
              }
            },
            Root: {
              style: ({ $theme }) => ({
                borderTopWidth: $theme.borders.borderWidth,
                borderBottomWidth: $theme.borders.borderWidth,
                borderLeftWidth: $theme.borders.borderWidth,
                borderRightWidth: $theme.borders.borderWidth,
                minHeight: $theme.sizing.scale1000,
                ...overrides?.Root?.style,
                ...inputRootConditionalStyles
              })
            },
            ...overrides
          }}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    )
  }
)

export default VInput
