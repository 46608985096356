import { StatusCodes } from 'components/constants/http-status-codes'
import { PAGE_SIZE } from 'components/constants/page-size'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { useHttp } from 'components/hooks/use-http'
import { usePagination } from 'components/hooks/use-pagination'
import { YARD_JOCKEY_AUDIENCE } from 'components/models/Role'
import {
  Task,
  TASK_ACCEPTED,
  TASK_COMPLETED,
  TASK_CREATED,
  TASK_STARTED,
  TASK_UNASSIGNED,
  TaskEvent
} from 'components/models/Task'
import { TrailerDetails } from 'components/models/Trailer'
import tasksReducer, {
  SET_TASKS,
  SET_LOADING,
  SET_TASK,
  SET_MODAL_LOADING,
  SET_MODAL,
  SET_VIEW,
  SET_CONFIRMATION_MODAL,
  SET_CONFIRMATION_LOADING,
  SET_TASK_MODAL,
  SET_FACILITY,
  SET_TRAILER_DETAILS,
  SET_DATE
} from 'components/reducers/tasks.reducer'
import { taskService } from 'components/services/task.service'
import { trailerService } from 'components/services/trailer.service'
import { userService } from 'components/services/user.service'
import { TasksPaginationResponse } from 'components/types/pagination-response'
import { COLUMN, ROW, ViewType } from 'components/types/viewType'
import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { useDebounce } from 'react-use'

export interface TasksContextProps {
  tasks: {
    results: Task[]
    events: TaskEvent[]
    loading: boolean
    total: number
    unassigned: number
    assigned: number
    completed: number
    view: ViewType
    confirmationModal: {
      loading: boolean
      open: boolean
      event: TaskEvent
    }
  }
  selectedFacility: any
  search: string
  selectedDate: Date
  taskModal: {
    isOpen: boolean
    loading: boolean
    isEdit: boolean
    task: Task
    trailer: {
      loading: boolean
      trailerInfo: TrailerDetails
    }
  }
  pagination?: {
    page: number
    pageSize: number
  }
  users: any[] | null
}

const initialState: TasksContextProps = {
  tasks: {
    results: [],
    events: [],
    loading: false,
    total: 0,
    unassigned: 0,
    assigned: 0,
    completed: 0,
    view: ROW,
    confirmationModal: {
      loading: false,
      open: false,
      event: null
    }
  },
  selectedFacility: [],
  search: null,
  selectedDate: new Date(),
  taskModal: {
    isOpen: false,
    isEdit: false,
    loading: false,
    task: null,
    trailer: {
      loading: false,
      trailerInfo: null
    }
  },
  pagination: {
    page: 1,
    pageSize: PAGE_SIZE
  },
  users: null
}

interface TasksContextType {
  state: TasksContextProps
  actions: {
    setPage: (number: number) => void
    setTask: (task: Task) => void
    setDate: (date: Date) => void
    createTask: () => void
    setModal: (open: boolean) => void
    setView: (view: ViewType) => void
    validateDrop: (event: TaskEvent) => void
    cancelConfirmationModal: () => void
    confirmConfirmationModal: () => void
    onConfirmUpdateTask: () => void
    closeTaskModal: () => void
    setSelectedFacility: (facility: any) => void
    setEditTask: (event: TaskEvent) => void
    onEventClick: (event: TaskEvent) => void
  }
}

export const TasksContext = createContext<TasksContextType | undefined>(undefined)

export const TasksProvider = ({ children }) => {
  const { currentUser } = useContext(CurrentUserContext)
  const [state, dispatch] = useReducer(tasksReducer, initialState)
  const { page, pageSize, setPage } = usePagination()

  const [findTasksPaginted, { data, loading }] = useHttp<TasksPaginationResponse<Task>>(
    taskService.getTasksPaginated
  )

  const [loadUsers, { data: users, loading: loadingUsers }] = useHttp<any>(
    userService.getUsersByRoleAudience
  )
  const [findTasks, { data: taskEvents, loading: loadingEvents }] = useHttp<any>(
    taskService.getTasks
  )
  const loadTasks = () => {
    const date = new Date(
      Date.UTC(
        state.selectedDate.getFullYear(),
        state.selectedDate.getMonth(),
        state.selectedDate.getDate(),
        0,
        0,
        0,
        0
      )
    )
    if (state.tasks.view === COLUMN && state.selectedFacility.length > 0) {
      findTasks({
        search: state.search,
        facilityId: state.selectedFacility[0]?.id,
        date: date
      })
    }
    if (state.tasks.view === ROW && state.selectedFacility.length > 0) {
      findTasksPaginted({
        page,
        pageSize,
        search: state.search,
        facilityId: state.selectedFacility[0]?.id,
        date: date
      })
    }
  }

  useDebounce(
    () => {
      loadTasks()
    },
    200,
    [page, pageSize, state.search, state.selectedFacility, state.tasks.view, state.selectedDate]
  )

  useDebounce(() => loadUsers([YARD_JOCKEY_AUDIENCE]), 200, [currentUser?.shipperId])

  const reloadTasks = () => {
    loadTasks()
    loadUsers([YARD_JOCKEY_AUDIENCE])
  }
  const formatTaskEvents = (tasks: Task[]): TaskEvent[] => {
    return tasks?.map(task => ({
      ...task,
      userId: task?.user?.id,
      rank: `${task.rank}`,
      userName: task?.user?.name,
      assigned: task?.user?.id || TASK_UNASSIGNED,
      assignedPrev: task?.user?.id || TASK_UNASSIGNED,
      status: task.completedAt
        ? TASK_COMPLETED
        : task.startedAt
        ? TASK_STARTED
        : task.acceptedAt
        ? TASK_ACCEPTED
        : TASK_CREATED
    }))
  }

  useEffect(() => {
    dispatch({
      type: SET_TASKS,
      payload: {
        ...state.tasks,
        results: data?.results || [],
        total: data?.total,
        unassigned: data?.unassigned,
        assigned: data?.assigned,
        completed: data?.completed
      }
    })
  }, [data])

  useEffect(() => {
    dispatch({
      type: SET_TASKS,
      payload: {
        ...state.tasks,
        events: formatTaskEvents(taskEvents?.results) || [],
        total: taskEvents?.total,
        unassigned: taskEvents?.unassigned,
        assigned: taskEvents?.assigned,
        completed: taskEvents?.completed
      }
    })
  }, [taskEvents])

  useEffect(() => {
    dispatch({ type: SET_LOADING, payload: loading || loadingEvents || loadingUsers })
  }, [loading, loadingEvents, loadingUsers])

  useDebounce(() => loadTrailerDetails(state.taskModal.task?.trailerId), 200, [
    state.taskModal.task?.trailerId
  ])

  const loadTrailerDetails = async (trailerId: string) => {
    setTrailerLoading(true)
    try {
      if (trailerId) {
        const [trailer, status] = await trailerService.getTrailerDetailsById(trailerId)
        if (status == StatusCodes.OK) {
          dispatch({
            type: SET_TRAILER_DETAILS,
            payload: {
              trailerInfo: {
                ...trailer
              }
            }
          })
          setFrom(trailer)
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setTrailerLoading(false)
    }
  }

  const setFrom = (trailer: TrailerDetails) => {
    if (trailer?.dock?.id) {
      setTask({
        ...state.taskModal.task,
        dock: trailer.dock
      })
    } else if (trailer?.slot?.id) {
      setTask({
        ...state.taskModal.task,
        fromSlot: trailer.slot,
        fromSlotId: trailer.slot.id
      })
    } else {
      setTask({
        ...state.taskModal.task,
        fromSlot: null,
        fromSlotId: null,
        dock: null,
        dockId: null
      })
    }
  }

  const setTrailerLoading = (value: boolean) => {
    dispatch({
      type: SET_TRAILER_DETAILS,
      payload: {
        loading: value
      }
    })
  }

  const setSelectedFacility = (facility: any) => {
    dispatch({ type: SET_FACILITY, payload: facility })
  }

  const setModal = (open: boolean) => {
    dispatch({ type: SET_MODAL, payload: open })
  }

  const setTask = (task: Task) => {
    dispatch({ type: SET_TASK, payload: task })
  }

  const setModalLoading = (loading: boolean) => {
    dispatch({ type: SET_MODAL_LOADING, payload: loading })
  }

  const createTask = async () => {
    setModalLoading(true)
    const json = await taskService.createTask(state.taskModal.task)
    if (json) {
      closeTaskModal()
      reloadTasks()
    }
  }

  const updateTask = async task => {
    setModalLoading(true)
    try {
      const data = await taskService.updateTask(task)
    } finally {
      closeTaskModal()
      reloadTasks()
    }
  }

  const updateAssign = async task => {
    setConfirmationLoading(true)
    try {
      const data = await taskService.updateTask(task)
      dispatch({ type: SET_CONFIRMATION_MODAL, payload: initialState.tasks.confirmationModal })
    } finally {
      setConfirmationLoading(false)
      reloadTasks()
    }
  }

  const setView = (view: ViewType) => {
    dispatch({ type: SET_VIEW, payload: view })
  }

  const closeTaskModal = () => {
    dispatch({
      type: SET_TASK_MODAL,
      payload: { ...initialState.taskModal }
    })
  }

  const validateDrop = (event: TaskEvent) => {
    dispatch({ type: SET_CONFIRMATION_MODAL, payload: { open: true, event: event } })
  }

  const cancelConfirmationModal = () => {
    dispatch({ type: SET_CONFIRMATION_MODAL, payload: initialState.tasks.confirmationModal })
    reloadTasks()
  }
  const confirmConfirmationModal = () => {
    const event = state.tasks?.confirmationModal?.event
    const data = {
      ...event,
      userId: event.assigned === TASK_UNASSIGNED ? null : event.assigned,
      acceptedAt: null
    }
    updateAssign(data)
  }

  const onConfirmUpdateTask = () => {
    updateTask(state.taskModal.task)
  }

  const setConfirmationLoading = (loading: boolean) => {
    dispatch({ type: SET_CONFIRMATION_LOADING, payload: loading })
  }

  const setEditTask = (task: TaskEvent) => {
    dispatch({ type: SET_TASK_MODAL, payload: { isOpen: true, task: task, isEdit: true } })
  }

  const onEventClick = (task: TaskEvent) => {
    setEditTask(task)
  }

  const setDate = (date: Date) => {
    dispatch({ type: SET_DATE, payload: date })
  }

  const actions = {
    setPage,
    setTask,
    setDate,
    setView,
    setModal,
    createTask,
    setEditTask,
    setSelectedFacility,
    validateDrop,
    onEventClick,
    closeTaskModal,
    onConfirmUpdateTask,
    cancelConfirmationModal,
    confirmConfirmationModal
  }

  const [usersData, status] = users || []

  return (
    <TasksContext.Provider
      value={{
        state: { ...state, pagination: { page: page, pageSize: pageSize }, users: usersData },
        actions
      }}>
      {children}
    </TasksContext.Provider>
  )
}

export const useTasksContext = (): TasksContextType => {
  const context = useContext(TasksContext)
  if (context === undefined) {
    throw new Error('useTasksContext must be used within an TasksProvider')
  }
  return context
}
