import React, { useEffect, useState } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import Select from 'components/ui/generic/Select'
import { useDebounce } from 'react-use'
import { facilityService } from '../../services'
import { sortBy } from 'lodash'
import { AppTypeKeys, useYardScheduleContext } from '../../contexts/yard-schedule.context'

const JBUILDER_TEMPLATE_PREFIX = 'hits'
const ATTRIBUTE = 'facility_id'
const DEFAULT_REFINEMENT = [ATTRIBUTE]

const NonConnectedYardScheduleFacilitySelect = ({ refine }) => {
  const [search, setSearch] = useState<string>('')
  const [options, setOptions] = useState([])
  const [facility, setFacility] = useState([])

  const {
    selectedFacility,
    facilityLoading,
    actions: { setSelectedFacility, setFacilityLoading }
  } = useYardScheduleContext()

  useEffect(() => {
    if (selectedFacility) {
      refine(selectedFacility.id)
      setFacility(selectedFacility)
    }
  }, [selectedFacility])

  useDebounce(
    async () => {
      const { hits } = await facilityService.findFacilitiesWithoutPagination({
        search,
        filters: [
          {
            appointment_preferences: {
              enable_yard_management: true
            }
          }
        ],
        template: JBUILDER_TEMPLATE_PREFIX
      })

      setOptions(sortBy(hits, 'createdAt'))

      if (hits?.length >= 1) {
        onChange([hits[0]])
      }
    },
    200,
    [search]
  )

  const onChange = (
    value: {
      id: string
      label: string
    }[]
  ) => {
    setFacilityLoading(true)
    facilityService.getFacility(value[0].id, 'yard_schedule').then(result => {
      if (result && result?.constructor === Object && Object.keys(result).length > 0) {
        refine(value[0].id)
        setSearch('')
        setSelectedFacility(result)
        setFacilityLoading(false)
      }
    })
  }

  return (
    <Select
      clearable={false}
      backspaceClearsInputValue
      maxDropdownHeight="250px"
      deleteRemoves
      value={facility}
      onChange={({ value }) => {
        onChange(value)
      }}
      placeholder=""
      options={options}
      labelKey="name"
      onInputChange={e => {
        setSearch(e.currentTarget.value)
      }}
      minWidth="135px"
      isLoading={facilityLoading}
    />
  )
}

const ConnectedYardScheduleFacilitySelect = connectRefinementList(
  NonConnectedYardScheduleFacilitySelect
)

const YardScheduleFacilitySelect = () => {
  return (
    <ConnectedYardScheduleFacilitySelect
      attribute={ATTRIBUTE}
      defaultRefinement={DEFAULT_REFINEMENT}
    />
  )
}

export default YardScheduleFacilitySelect
