import React from 'react'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { Tag, KIND } from 'baseui/tag'
import { ArrowLineDown, ArrowLineUp } from '@phosphor-icons/react'
import { Block, BlockProps } from 'baseui/block'
import { Card, StyledBody } from 'baseui/card'
import LabelField from './LabelField'
import { Label1, Label2 } from 'baseui/typography'

// Icons
import * as Icons from 'react-icons/fa'
import { Drop, Van, Truck, Snowflake, LadderSimple, BatteryFull } from '@phosphor-icons/react'
import { Icon } from 'baseui/icon'
import { trailerStatusTagProps } from './AppointmentCard.styled'
import { primitives } from 'components/homepage/custom-theme'

const itemProps: BlockProps = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'center'
}
const DOCK_TYPE_ICON = {
  ['van']: Van,
  ['tanker']: Drop,
  ['flatbed']: Truck,
  ['reefer']: Snowflake,
  ['container']: BatteryFull,
  ['rail']: LadderSimple,
  ['Van (Floor Loaded)']: Van,
  ['Van (Pallet Loaded)']: Van,
  ['van_floor']: Van,
  ['van_pallet']: Van
}

interface AppointmentCardProps {
  appointment: any
  selected?: boolean
  onClick?: (event: Event) => any
}

const DEFAULT_ICON_NAME = 'FaPallet'
export const AppointmentCard = ({ appointment, selected, onClick }: AppointmentCardProps) => {
  const { schedulerName, purchaseOrderIdentifiers } = appointment

  const fields = [
    { key: 'purchaseOrderIdentifiers', label: 'ID and type', capitalize: false, showIcon: true },
    { key: 'confirmationId', label: 'Appointment ID', capitalize: false },
    { key: 'handlingMethod', label: 'Handling', capitalize: true },
    { key: 'appointmentType.type', label: 'Appointment direction', capitalize: true },
    { key: 'appointmentType.appointmentTypeName', label: 'Appointment Type', capitalize: true },
    { key: 'trailer.trailerNumber', label: 'Trailer #', capitalize: false },
    { key: 'carrier.carrierName', label: 'Carrier', capitalize: false },
    { key: 'driverName', label: 'Driver name', capitalize: false }
  ]
  const IconComponent =
    DOCK_TYPE_ICON[appointment.equipmentType.equipmentTypeName] ||
    Icons[appointment.equipmentType.equipmentTypeIcon] ||
    Icons[DEFAULT_ICON_NAME]

  return (
    <Block onClick={onClick}>
      <Card
        overrides={{
          Root: {
            style: {
              backgroundColor: 'white',
              borderColor: selected ? primitives.accent : '#dbe6ff',
              marginTop: '16px',
              width: '100%',
              cursor: onClick ? 'pointer' : 'inherit'
            }
          }
        }}>
        <StyledBody>
          <Block
            minWidth={'100%'}
            display="flex"
            justifyContent="space-between"
            alignItems="center">
            <Block display={'flex'} alignItems={'center'} gridColumnGap={'5px'}>
              <IconComponent size="16px" />
              <Label1>{schedulerName}</Label1>
            </Block>
            <Block display="flex" flexDirection="row" alignItems="center" gridColumnGap={'5px'}>
              <Label2>{purchaseOrderIdentifiers}</Label2>
              {appointment.appointmentType.type?.includes('Inbound') ? (
                <ArrowLineDown size={12} color="#082766" />
              ) : (
                <ArrowLineUp size={12} color="#082766" />
              )}
              {appointment.trailer?.trailerStatus && (
                <Tag kind={KIND.blue} closeable={false} {...trailerStatusTagProps}>
                  {appointment.trailer.trailerStatus}
                </Tag>
              )}
            </Block>
          </Block>
          <Block marginTop="scale400" />
          <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale800" flexGridRowGap="scale800">
            <FlexGridItem {...itemProps}>
              {fields.slice(0, 4).map(({ key, label, capitalize, showIcon }) => (
                <LabelField
                  pointer={key}
                  field={label}
                  appointment={appointment}
                  capitalize={capitalize}
                  showIcon={showIcon}
                />
              ))}
            </FlexGridItem>
            <FlexGridItem {...itemProps}>
              {fields.slice(4).map(({ key, label, capitalize, showIcon }) => (
                <LabelField
                  pointer={key}
                  field={label}
                  appointment={appointment}
                  capitalize={capitalize}
                  showIcon={showIcon}
                />
              ))}
            </FlexGridItem>
          </FlexGrid>
        </StyledBody>
      </Card>
    </Block>
  )
}

export default AppointmentCard
