/* eslint-disable @typescript-eslint/no-explicit-any */
import { createTheme, lightThemePrimitives } from 'baseui'

export const primitives = {
  ...lightThemePrimitives,
  primaryFontFamily: 'Inter',
  primary: '#082766',
  accent: '#2666FF',
  negative: '#D92179',
  positive: '#0C8564',
  warning: '#C4511D',
  error: '#D92179',
  text: '#04112B',
  light: '#F7FCFF',
  darklight: '#f0f9ff',
  white: '#FFF',
  gray: '#DBE6FF',
  blueAlpha: '#0827664d',
  grayAlpha: '#dbe6ff33',
  transparent: 'transparent'
}

const overrides = {
  colors: {
    backgroundPrimary: primitives.light,
    backgroundSecondary: primitives.white,
    backgroundTertiary: primitives.gray,
    borderSelected: primitives.accent,
    inputFill: primitives.white,
    inputFillDisabled: '#dbe6ff4d',
    inputTextDisabled: '#939eb8',
    contentPrimary: primitives.text,
    contentSecondary: primitives.primary,
    tabBarFill: primitives.transparent,
    linkText: primitives.accent,
    linkHover: primitives.primary,
    toastInfoBackground: '#2666FF',
    toastPositiveBackground: '#0C8564',
    toastWarningBackground: '#C4511D',
    toastNegativeBackground: '#D92179',
    inputBorder: primitives.gray,
    buttonDisabledFill: primitives.gray,
    buttonDisabledText: '#939eb8',
    ratingInactiveFill: primitives.gray,
    ratingStroke: primitives.primary,
    tableHeadBackgroundColor: primitives.light,
    buttonSecondaryFill: primitives.light,
    buttonSecondaryHover: primitives.white,
    buttonSecondaryActive: primitives.white,
    buttonSecondarySelectedFill: primitives.white,
    buttonSecondarySelectedText: primitives.accent,
    tagWarning: '#ffede5'
  },
  typography: {
    HeadingSmall: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '24px',
      fontFamily: 'Rubik'
    },
    HeadingXSmall: {
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '24px',
      fontFamily: 'Rubik'
    },
    LabelSmall: {
      fontSize: '13px'
    },
    LabelXSmall: {
      fontSize: '11px'
    }
  },
  borders: {
    borderColor: '#B5BED1',
    borderWidth: '1px',
    borderStyle: 'solid',
    useRoundedCorners: true,
    buttonBorderRadius: '32px',
    inputBorderRadius: '24px',
    popoverBorderRadius: '16px',
    surfaceBorderRadius: '16px',
    radius50: '2px',
    radius100: '4px',
    radius200: '8px',
    radius300: '16px',
    radius400: '24px',
    radius500: '32px',
    border200: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: primitives.gray
    }
  }
}

const breakpoints = {
  small: 320,
  medium: 768,
  large: 1280,
  xlarge: 1440
}

const breakpointMediaQueries = Object.keys(breakpoints).reduce(
  (acc, key) => {
    acc.mediaQuery[key] = `@media screen and (min-width: ${breakpoints[key]}px)`
    return acc
  },
  {
    breakpoints,
    mediaQuery: {} as any
  }
)

const customColorTheme = createTheme(primitives, overrides)

export const customTheme = {
  ...customColorTheme,
  ...breakpointMediaQueries
}
