import PremiumCalendar from 'components/premium-calendar'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import React, { useEffect, useRef, useContext, useState } from 'react'
import { connectHits } from 'react-instantsearch-dom'
import rrulePlugin from '@fullcalendar/rrule'
import scrollGridPlugin from '@fullcalendar/scrollgrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'
import { CalendarReferenceContext } from 'components/contexts/calendar-reference-context'
import TimeFormatter from 'components/utils/time-formatter'
import { CurrentUserContext } from '../../homepage/current-user-context'
import InstantDatePicker from '../../shipper/instant-calendar/toggles/instant-date-picker'
import CalendarPopover from '../../shipper/instant-calendar/calendar-popover'
import { DEFAULT_SLOT_DURATIONS } from '../../shipper/instant-calendar/hooks/use-facility-info'
import { useTranslation } from 'react-i18next'
import EventTile from 'components/ui/specific/EventTile'
import { getDateFilterFormat } from '../../shipper/instant-calendar/calendar-hits'
import _isEqual from 'lodash/isEqual'
import StyledSpinner from '../../shared/styled-spinner'
import { Block } from 'baseui/block'
import {
  FIXED_DOCK_DOORS_COLUMN,
  FIXED_UNASSIGNED_COLUMN,
  useYardScheduleContext
} from '../../contexts/yard-schedule.context'
import getTrailers from './hooks/get-trailers'
import YardScheduleDropModal from './YardScheduleDropModal'
import { fancyToast } from '../../utils'
import YardCapacityAlert from '../../components/YardCapacityAlert'
import { ConfirmationModal } from 'components/components/ConfirmationModal'

const APPOINTMENT_CHECKED_OUT_STATUS = 'checked out'

export const NonConnectedYardScheduleCalendar = ({
  hits,
  timeRange,
  selectedDate,
  setSelectedDate,
  setNumberOfHits
}) => {
  const { calendarReference, setDateInCalendarFilter, setCacheDate, cacheDate } =
    useContext(CalendarReferenceContext)
  const { currentUser } = useContext(CurrentUserContext)

  const {
    facilityLoading,
    selectedFacility,
    zones,
    loading,
    cacheHits,
    dropConfirmationModalIsOpen,
    dropConfirmationModalTitle,
    actions: {
      openDropModal,
      overflowsZoneCapacity,
      setCacheHits,
      cancelDropConfirmationModal,
      confirmDropConfirmationModal
    }
  } = useYardScheduleContext()

  const { t } = useTranslation()

  // This is in charge of updating the cache only if hits are different from what cache already has
  // this way we avoid updating the calendar event's state on every props change or render
  useEffect(() => {
    if (!_isEqual(hits, cacheHits) && hits.length > 0) {
      setCacheHits(hits)
    }
  }, [hits])

  const { trailers, unassignedTotals } = getTrailers(cacheHits, selectedFacility)

  const [showDatepicker, setShowDatepicker] = useState(false)

  const setDateAndCache = (newDate: Date) => {
    setSelectedDate(newDate)
    const dateFormatted = getDateFilterFormat(newDate)
    setDateInCalendarFilter(dateFormatted)
    setCacheDate({
      ...cacheDate,
      [currentUser.shipperId]: {
        ...((cacheDate && cacheDate[currentUser.shipperId]) || {}),
        [currentUser.id]: dateFormatted
      }
    })
  }

  const handleCustomPrevClick = () => {
    const newDate = selectedDate
    newDate.setDate(newDate.getDate() - 1)
    setDateAndCache(newDate)
  }

  const handleCustomTodayClick = () => {
    setDateAndCache(new Date())
  }

  const handleCustomNextClick = () => {
    const newDate = selectedDate
    newDate.setDate(newDate.getDate() + 1)
    setDateAndCache(newDate)
  }

  const datepicker = () => {
    setShowDatepicker(!showDatepicker)
  }

  const customResourceOrder = (a, b) => {
    if (a.id === FIXED_UNASSIGNED_COLUMN.id) {
      return -1
    }
    if (b.id === FIXED_DOCK_DOORS_COLUMN.id) {
      return 1
    }
    return a.title.localeCompare(b.title)
  }

  const selectedDateRef = useRef(selectedDate)

  useEffect(() => {
    function filterHitsByRange(hits, timeRange) {
      const minTimeHours = parseInt(timeRange.minTime[0].label.split(':')[0], 10)
      const minTimeMinutes = parseInt(timeRange.minTime[0].label.split(':')[1], 10)
      const maxTimeHours = parseInt(timeRange.maxTime[0].label.split(':')[0], 10)
      const maxTimeMinutes = parseInt(timeRange.maxTime[0].label.split(':')[1], 10)

      const filteredHits = hits.filter(hit => {
        const arrivalTime = new Date(hit.arrival_time * 1000)
        const arrivalHours = arrivalTime.getHours()
        const arrivalMinutes = arrivalTime.getMinutes()

        return (
          (arrivalHours > minTimeHours ||
            (arrivalHours === minTimeHours && arrivalMinutes >= minTimeMinutes)) &&
          (arrivalHours < maxTimeHours ||
            (arrivalHours === maxTimeHours && arrivalMinutes < maxTimeMinutes))
        )
      })

      return filteredHits
    }
    selectedDateRef.current = selectedDate
    if (timeRange.maxTime[0].label !== '24:00' || timeRange.minTime[0].label !== '00:00') {
      const filteredHits = filterHitsByRange(hits, timeRange)
      setNumberOfHits(filteredHits.length)
    } else {
      setNumberOfHits(hits.length)
    }
  }, [selectedDate, timeRange, hits, setNumberOfHits])

  const eventDrop = info => {
    const { oldResource, newResource, event } = info

    if (event.extendedProps?.status === APPOINTMENT_CHECKED_OUT_STATUS) {
      fancyToast({ info: t('YardSchedule.Errors.CheckedOutDragAndDrop.Text') }, 500)
      info.revert()
      return
    }

    if (newResource.id === FIXED_UNASSIGNED_COLUMN.id) {
      fancyToast({ info: t('YardSchedule.Errors.GoingBackToUnassigned.Text') }, 500)
      info.revert()
      return
    }

    if (overflowsZoneCapacity(newResource.title)) {
      fancyToast({ info: t('YardSchedule.Errors.ZoneCapacityOverflow.Text') }, 500)
      info.revert()
      return
    }

    if (!event.extendedProps?.trailerId) {
      fancyToast({ info: t('YardSchedule.Errors.AppointmentStillActive.Text') }, 500)
      info.revert()
      return
    }

    if (oldResource.id === FIXED_UNASSIGNED_COLUMN.id) {
      openDropModal({
        dropModalFromZoneName: oldResource.title,
        dropModalAppointmentId: event.id,
        dropModalToZoneId: newResource.id,
        dropModalToZoneName: newResource.title,
        dropModalForDriver: true,
        selectedAppointment: event,
        info
      })
    } else {
      openDropModal({
        dropModalFromZoneName: oldResource.title,
        dropModalAppointmentId: event.id,
        dropModalToZoneId: newResource.id,
        dropModalToZoneName: newResource.title,
        dropModalForDriver: false,
        selectedAppointment: event,
        info
      })
    }
  }

  const generateCustomResourceLabelContent = resourceObject => {
    const { occupiedSlots, totalSlots } = resourceObject.resource.extendedProps || {}
    const { title } = resourceObject.resource

    return {
      html: `<div class="yard-custom-resource-label"><div class="yard-custom-resource-title">${title}</div>${`<div class="yard-custom-resource-slots"><div>${occupiedSlots}${
        totalSlots !== undefined ? ` / ${totalSlots}` : ''
      }</div></div>`}<div>`
    }
  }

  return loading || facilityLoading ? (
    <Block display="flex" justifyContent="center" alignItems="center" height="500px">
      <StyledSpinner />
    </Block>
  ) : (
    <div className="day-calendar">
      <CalendarPopover isOpen={showDatepicker} togglePopover={setShowDatepicker}>
        <InstantDatePicker attribute="arrival_time" />
      </CalendarPopover>
      <YardCapacityAlert facilityIds={[selectedFacility?.id]} date={selectedDate} />
      <PremiumCalendar
        aspectRatio={1.6}
        height="74vh"
        innerRef={calendarReference}
        editable
        eventStartEditable={false}
        eventResizableFromStart={false}
        eventDurationEditable={false}
        eventDrop={eventDrop}
        plugins={[
          rrulePlugin,
          timeGridPlugin,
          interactionPlugin,
          momentPlugin,
          scrollGridPlugin,
          resourceTimeGridPlugin
        ]}
        slotDuration={
          DEFAULT_SLOT_DURATIONS[selectedFacility?.appointmentDuration || 1800] || '00:30'
        }
        slotLabelFormat={
          {
            hourCycle: 'h23',
            hour: '2-digit',
            minute: '2-digit'
          } as any
        }
        resourceLabelContent={resourceObject => generateCustomResourceLabelContent(resourceObject)}
        initialView="resourceTimeGridDay"
        allDaySlot={false}
        dayMinWidth={220}
        customButtons={{
          customPrevButton: {
            icon: 'chevron-left',
            click: handleCustomPrevClick
          },
          customTodayButton: {
            text: t('YardSchedule.Calendar.Today.Text'),
            click: handleCustomTodayClick
          },
          customNextButton: {
            icon: 'chevron-right',
            click: handleCustomNextClick
          },
          datepicker: {
            text: '',
            click: datepicker
          }
        }}
        headerToolbar={{
          left: 'title datepicker customPrevButton customTodayButton customNextButton',
          center: '',
          right: ''
        }}
        events={trailers}
        eventContent={({ event }) => {
          return <EventTile event={event} />
        }}
        titleFormat={{
          month: 'short',
          weekday: 'short',
          day: 'numeric'
        }}
        nowIndicator
        expandRows
        slotMinTime={`${timeRange && timeRange.minTime[0].label}:00`}
        slotMaxTime={`${timeRange && timeRange.maxTime[0].label}:00`}
        resourceAreaWidth={220}
        resourceOrder={customResourceOrder}
        resources={
          selectedFacility
            ? [
                {
                  ...FIXED_UNASSIGNED_COLUMN,
                  extendedProps: {
                    occupiedSlots: unassignedTotals.occupiedSlots
                  }
                },
                ...zones
              ]
            : []
        }
        dayHeaderContent={({ date }) => {
          return new TimeFormatter('humanDate').format(date)
        }}
      />
      <YardScheduleDropModal />
      <ConfirmationModal
        isOpen={dropConfirmationModalIsOpen}
        title={dropConfirmationModalTitle}
        cancelAction={cancelDropConfirmationModal}
        confirmAction={confirmDropConfirmationModal}
        loading={false}
      />
    </div>
  )
}

const YardScheduleCalendar = connectHits(NonConnectedYardScheduleCalendar) as any

export default YardScheduleCalendar
