import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Block, BlockProps } from 'baseui/block'
import { ParagraphXSmall } from 'baseui/typography'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import ButtonGroup from '../../components/ButtonGroup'
import Divider from '../../ui/generic/Divider'
import Checkbox from '../../ui/generic/Checkbox'
import {
  Truck,
  ArrowLineUp,
  ArrowLineDown,
  BatteryFull,
  Check,
  X,
  TrashSimple
} from '@phosphor-icons/react'
import SchedulerEmail from 'components/facilities/steps/fields/scheduler-email'
import FacilitySelect from '../../facilities/steps/fields/facility-select'
import AppointmentForm from './../CreateAppointmentForExternalUsers/AppointmentForm'
import { DateTime } from './../CreateAppointmentForExternalUsers/DateTime'
import {
  INBOUND,
  IS_DROP,
  OUTBOUND,
  useAppointmentContext
} from 'components/contexts/appointment-context'
import { useFacilityContext } from 'components/contexts/facility-context'
import { CustomQuestionsProvider } from 'components/contexts/custom-questions.context'
import { INBOUND_TYPE, OUTBOUND_TYPE } from 'components/constants/appointment_types'
import ErrorMessageButton from 'components/shared/error-message-button'
import TermsOfServiceModal from './../CreateAppointmentForExternalUsers/TermsOfServiceModal/TermsOfServiceModal'
import { useCarriersContext } from 'components/contexts/carriers.context'
import RescheduleReason from './Reschedule-reason'
import Recaptcha from 'components/shared/recaptcha'
import CancelButton from './cancel-button'
import { DROP, LIVE } from 'components/constants/handling_method'
import { handlingMethodButtons } from 'components/shared/buttons/liveDropButtons'
import { appointmentTypesButtons } from 'components/shared/buttons/appointmentTypeButtons'

const itemProps: BlockProps = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start'
}

const AppointmentReusableForm: React.FC = () => {
  const { state, actions } = useAppointmentContext()
  const {
    actions: { setIsVendorSelectDisabled }
  } = useCarriersContext()

  // AppointmentContext state
  const {
    appointmentId,
    loading,
    handlingMethod,
    appointmentDirections,
    schedulerEmail,
    createAppointmentButtonDisableFlags,
    termsOfService,
    appointments,
    appointmentEdit: { rescheduleReason, recaptcha: recaptchaCompleted }
  } = state

  const {
    setHandlingMethod,
    setAppointmentDirection,
    setSchedulerEmail,
    resetAppointmentStates,
    setShowTermsOfServiceModal,
    setRescheduleReason,
    updateAppointment,
    setRecaptcha,
    getAppointmentIds
  } = actions

  const { isCreateAppointmentButtonDisabled, errors } = createAppointmentButtonDisableFlags

  const { state: facilityState } = useFacilityContext()
  const { facility } = facilityState

  const { t } = useTranslation()

  const is_inbound = appointmentDirections.includes(INBOUND)
  const is_outbound = appointmentDirections.includes(OUTBOUND)
  const hasHandlingMethod = handlingMethod !== ''

  const returnErrors = () => {
    const errorsValidations = errors.map(error => ({ label: error.label, status: !error.status }))
    errorsValidations.push({
      label: t('Appointments.EditAppointmentModal.Validations.RescheduleReasonRequired.Text'),
      status: rescheduleReason && rescheduleReason?.length > 0
    })
    errorsValidations.push({
      label: t('Appointments.EditAppointmentModal.Validations.ReCaptchaRequired.Text'),
      status: recaptchaCompleted
    })

    if (
      handlingMethod === DROP &&
      appointmentDirections &&
      appointments[appointmentDirections[0]]
    ) {
      errorsValidations.push({
        label: t('Appointments.EditAppointmentModal.Validations.TrailerRequired.Text'),
        status: !!appointments[appointmentDirections[0]].trailer.number
      })
    }

    return errorsValidations
  }

  const appointmentIds = getAppointmentIds()

  useEffect(() => {
    setIsVendorSelectDisabled?.(true)
  }, [])

  const onUpdateAppointment = async () => {
    await updateAppointment()
  }

  const onCloseDeleteAppointment = deleted => {
    if (deleted) {
      resetAppointmentStates()
    }
  }

  return (
    <>
      <Block minWidth="94%" padding="scale600">
        <ParagraphXSmall
          overrides={{
            Block: {
              style: ({ $theme }) => ({
                fontSize: '10px',
                color: $theme.colors.primary
              })
            }
          }}>
          * REQUIRED INFORMATION
        </ParagraphXSmall>
        <Block marginBottom="scale800" />
        <FlexGrid
          flexGridColumnCount={[1, 1, 2]}
          flexGridColumnGap="scale300"
          flexGridRowGap="scale300"
          overrides={{
            Block: {
              style: {
                width: '100%',
                display: 'flex',
                itemAlign: 'center'
              }
            }
          }}>
          {/* SCHEDULER EMAIL */}
          <FlexGridItem {...itemProps}>
            <Block minWidth="100%">
              <SchedulerEmail
                schedulerEmail={schedulerEmail}
                setSchedulerEmail={setSchedulerEmail}
              />
            </Block>
          </FlexGridItem>

          {/* FACILITY SELECT */}
          <FlexGridItem {...itemProps}>
            <Block minWidth="100%">
              <FacilitySelect disabled />
            </Block>
          </FlexGridItem>

          {/* HANDLING METHOD */}
          <FlexGridItem {...itemProps} overrides={{ Block: { style: { marginBottom: '0px' } } }}>
            <Block minWidth="100%">
              <ButtonGroup
                disabled
                mode="radio"
                label={t('Appointments.CreateAppointmentModal.Fields.HandlingMethod.Label.Text')}
                buttons={handlingMethodButtons(facility)}
                value={handlingMethod}
                onChange={setHandlingMethod}
              />
            </Block>
          </FlexGridItem>

          {/* APPOINTMENT CATEGORY */}
          <FlexGridItem {...itemProps} overrides={{ Block: { style: { marginTop: '0px' } } }}>
            <Block minWidth="100%">
              <ButtonGroup
                mode="checkbox"
                label={t('Scheduler.OpenScheduling.Header.AppointmentTypes.Text')}
                buttons={appointmentTypesButtons}
                value={appointmentDirections}
                onChange={setAppointmentDirection}
                visibility={handlingMethod === IS_DROP}
              />
            </Block>
          </FlexGridItem>
          <FlexGridItem {...itemProps}>
            <Block minWidth="100%">
              <Divider />
              {hasHandlingMethod && (
                <>
                  {!is_inbound && is_outbound ? (
                    <CustomQuestionsProvider>
                      <AppointmentForm appointmentDirection={OUTBOUND_TYPE} facility={facility} />
                    </CustomQuestionsProvider>
                  ) : (
                    <CustomQuestionsProvider>
                      <AppointmentForm appointmentDirection={INBOUND_TYPE} facility={facility} />
                    </CustomQuestionsProvider>
                  )}
                </>
              )}
            </Block>
          </FlexGridItem>
          <FlexGridItem {...itemProps}>
            <Block minWidth="100%" maxWidth={'96% '}>
              <Divider />
              {is_inbound && is_outbound && handlingMethod === IS_DROP && (
                <CustomQuestionsProvider>
                  <AppointmentForm appointmentDirection={OUTBOUND_TYPE} facility={facility} />
                </CustomQuestionsProvider>
              )}
            </Block>
          </FlexGridItem>
        </FlexGrid>
        <Block marginBottom="scale800" />
        <DateTime />
        <Block marginBottom="scale800" />
        <FlexGrid flexGridColumnCount={[1, 1, 2]} flexGridColumnGap="scale300">
          <FlexGridItem>
            <RescheduleReason
              value={rescheduleReason}
              onChange={value => {
                setRescheduleReason(value)
              }}
            />
          </FlexGridItem>
          <FlexGridItem></FlexGridItem>
          <FlexGridItem>
            <Recaptcha
              onChange={() => {
                setRecaptcha(true)
              }}
            />
          </FlexGridItem>
          <FlexGridItem alignContent={'center'}>
            {facility.note && (
              <Block minWidth="100%" justifyContent={'flex-end'} display={'flex'}>
                <Checkbox
                  checked={termsOfService.isTermsOfServiceChecked}
                  onMouseUp={e => setShowTermsOfServiceModal(true)}
                  label={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'Scheduler.OpenScheduling.Steps.TermsOfService.TermsOfServiceCheckboxLabel.Text'
                        )
                      }}
                    />
                  }
                />
              </Block>
            )}
          </FlexGridItem>
        </FlexGrid>

        <Divider width="100%" />
        <Block width={'100%'} justifyContent={'flex-end'} display={'flex'}>
          <CancelButton
            appointmentIds={appointmentIds}
            close={onCloseDeleteAppointment}
            appointment={handlingMethod === LIVE && appointments.inbound}
            label={'Cancel Appointment'}
          />
          <Block marginRight="scale300" />
          <ErrorMessageButton
            label="Update appointment"
            errors={returnErrors()}
            disabled={
              isCreateAppointmentButtonDisabled ||
              (facility.note && !termsOfService.isTermsOfServiceChecked)
            }
            buttonProps={{
              onClick: onUpdateAppointment,
              type: 'submit',
              isLoading: loading,
              startEnhancer: <Check size={16} />
            }}
          />
        </Block>
      </Block>
      <TermsOfServiceModal termsOfServiceText={facility.note} />
    </>
  )
}

export default AppointmentReusableForm
