import React, { useState, useEffect } from 'react'
import Header from 'components/ui/generic/Header'
import { useTranslation } from 'react-i18next'
import { PurchaseOrderLabelsContext } from 'components/pages/CreateAppointmentForExternalUsers'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import { DEFAULT_SHIPPER_SUBDOMAIN } from '../../constants/default-shipper-subdomain'
import { Card, StyledBody } from 'baseui/card'
import { Block } from 'baseui/block'

import { AppointmentCard } from 'components/components/AppointmentCard'
import Divider from '../../ui/generic/Divider'
import { Link } from 'react-router-dom'
import SearchBar from './search-bar'
import { facilityService } from 'components/services'
import { Paragraph1, Paragraph2, Paragraph4, ParagraphSmall } from 'baseui/typography'
import { MagnifyingGlass } from '@phosphor-icons/react'
import { primitives } from '../../homepage/custom-theme'

const UpdateAppointmentOpenSchedulingSearch = () => {
  const subdomain = window.location.hostname.split('.')[0]
  const [appointments, setAppointments] = useState<any>({})
  const [hasDoneAtLeastOneSearch, sethasDoneAtLeastOneSearch] = useState<boolean>(false)
  const [mainAppointment, setMainAppointment] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)

  const { t } = useTranslation()

  useEffect(() => {
    facilityService
      .openSchedulingFacilities(subdomain === 'localhost' ? DEFAULT_SHIPPER_SUBDOMAIN : subdomain)
      .then(([facilityLabels, _status]) => {
        if (facilityLabels.length >= 1) {
          setMainAppointment(facilityLabels[0])
        }
      })
      .catch(console.log)
  }, [])

  return (
    <>
      <PurchaseOrderLabelsContext.Provider
        value={{
          purchaseOrderLabels:
            mainAppointment &&
            mainAppointment.appointmentPreference &&
            mainAppointment.appointmentPreference.purchase_order_field_names
        }}>
        <Header title={t('HeaderNavigation.NavBarLinks.UpdateAppointment.Text')} />
        <Block maxWidth={['100%', '100%', '80%', '60%']}>
          <Card
            overrides={{
              Root: {
                style: {
                  backgroundColor: 'white'
                }
              }
            }}>
            <StyledBody>
              <Block maxWidth={['100%', '100%', '70%', '75%']}>
                <Paragraph2>{t('Scheduler.Appointments.HeaderDrop.Title.Text')}</Paragraph2>
                <Paragraph4>
                  <small>{t('Scheduler.Appointments.HeaderDrop.Subtitle.Text')}</small>
                </Paragraph4>
                <SearchBar
                  setAppointments={setAppointments}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  sethasDoneAtLeastOneSearch={sethasDoneAtLeastOneSearch}
                />
              </Block>
            </StyledBody>
            <Divider />
            <Block
              marginTop="scale800"
              maxWidth={['100%', '100%', appointments.total > 0 ? '70%' : '100%']}>
              {appointments.total > 0
                ? appointments?.results?.map((appointment: any) => (
                    <Link
                      style={{
                        textDecoration: 'none'
                      }}
                      to={`/openscheduling/${appointment.id}/schedule`}>
                      <AppointmentCard appointment={appointment} />
                    </Link>
                  ))
                : hasDoneAtLeastOneSearch && (
                    <Block
                      display="flex"
                      justifyContent="space-between"
                      alignItems="start"
                      flexDirection="column">
                      <MagnifyingGlass size="32px" color={primitives.primary} />
                      <Paragraph1
                        overrides={{
                          Block: {
                            style: () => ({
                              color: primitives.primary,
                              fontWeight: 500,
                              marginBottom: 0,
                              fontFamily: 'Rubik',
                              size: '18px'
                            })
                          }
                        }}>
                        {t('Common.Errors.AppointmentsNotFound.Text')}
                      </Paragraph1>
                      <Paragraph2
                        overrides={{
                          Block: {
                            style: () => ({
                              color: primitives.primary,
                              marginTop: '9px',
                              fontFamily: 'Inter',
                              size: '16px'
                            })
                          }
                        }}>
                        {t('Common.Errors.AppointmentsNotFoundMessage.Text')}
                      </Paragraph2>
                    </Block>
                  )}
            </Block>
          </Card>
        </Block>
      </PurchaseOrderLabelsContext.Provider>
    </>
  )
}

export default UpdateAppointmentOpenSchedulingSearch
